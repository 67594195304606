<template>
  <div>
    <b-row>
      <b-col>
        <title-card />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <scode-card />
      </b-col>
      <b-col>
        <group-card />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <min-amount-card />
      </b-col>
      <b-col>
        <max-amount-card />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <amount-card />
      </b-col>
      <b-col>
        <units-card />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <notes-card />
      </b-col>
    </b-row>

  </div>
</template>
<script>
import ScodeCard from '@/views/Admin/Stocks/Elements/scodeCard.vue'
import { BRow, BCol } from 'bootstrap-vue'
import TitleCard from '@/views/Admin/Stocks/Elements/titleCard.vue'
import NotesCard from '@/views/Admin/Stocks/Elements/Notes.vue'
import UnitsCard from '@/views/Admin/Stocks/Elements/unitsCard.vue'
import MinAmountCard from '@/views/Admin/Stocks/Elements/min_amount.vue'
import MaxAmountCard from '@/views/Admin/Stocks/Elements/max_amount.vue'
import AmountCard from '@/views/Admin/Stocks/Elements/amountCard.vue'
import GroupCard from '@/views/Admin/Stocks/Elements/GroupsCard.vue'

export default {
  name: 'StockForm',
  components: {
    GroupCard,
    AmountCard,
    MaxAmountCard,
    MinAmountCard,
    UnitsCard,
    NotesCard,
    TitleCard,
    ScodeCard,
    BRow,
    BCol,
  },
  computed: {
    dataItem() {
      return this.$store.getters['stocks/dataItem']
    },
  },
}
</script>
