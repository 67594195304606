<template>
  <b-form-group
    label="En Düşük Stok Miktarı"
    label-for="min_amount"
  >
    <b-input-group :append="dataItem.units">
      <cleave
        id="min_amount"
        v-model="dataItem.min_amount"
        class="form-control"
        :raw="true"
        :options="options"
        placeholder="Giriniz(Opsiyon)"
      />
    </b-input-group>
  </b-form-group>
</template>
<script>
import { BFormGroup, BInputGroup } from 'bootstrap-vue'
import { required } from '@core/utils/validations/validations'
import Cleave from 'vue-cleave-component'

export default {
  name: 'MinAmountCard',
  components: {
    BFormGroup, BInputGroup, Cleave,
  },
  data() {
    return {
      required,
      options: {
        numeral: true,
        numeralDecimalMark: ',',
        delimiter: '.',
      },
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['stocks/dataItem']
    },
  },
}
</script>
