<template>
  <b-form-group
    label="Stok Kodu"
    label-for="scode"
  >
    <validation-provider
      #default="{ errors }"
      name="Stok Kodu"
      rules="required"
    >
      <b-form-input
        id="scode"
        v-model="dataItem.scode"
        placeholder="Stok Kodu"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>
<script>
import { BFormGroup, BFormInput } from 'bootstrap-vue'
import { localize, ValidationProvider } from 'vee-validate'
import { required } from '@core/utils/validations/validations'

export default {
  name: 'ScodeCard',
  components: { ValidationProvider, BFormInput, BFormGroup },
  data() {
    return {
      required,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['stocks/dataItem']
    },
  },
  created() {
    localize('tr')
  },
}
</script>
