<template>
  <b-form-group
    label="Grubu"
    label-for="id_stock_groups"
  >
    <validation-provider
      #default="{ errors }"
      name="Grubu"
      rules="required"
    >
      <v-select
        id="id_stock_groups"
        v-model="dataItem.id_stock_groups"
        :options="groups"
        label="title"
        :reduce="item => item.id"
        placeholder="Seçiniz"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>
<script>
import { BFormGroup } from 'bootstrap-vue'
import { localize, ValidationProvider } from 'vee-validate'
import { required } from '@core/utils/validations/validations'
import vSelect from 'vue-select'

export default {
  name: 'GroupCard',
  components: { ValidationProvider, vSelect, BFormGroup },
  data() {
    return {
      required,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['stocks/dataItem']
    },
    groups() {
      return this.$store.getters['stockGroups/dataList']
    },
  },
  created() {
    localize('tr')
    this.getGroups()
  },
  methods: {
    getGroups() {
      this.$store.dispatch('stockGroups/getDataList')
    },
  },
}
</script>
