<template>
  <div>
    <b-card title="Stok Kartı Ekle">
      <validation-observer ref="simpleRules">
        <stock-form />
      </validation-observer>
    </b-card>
    <div class="text-left">
      <b-button
        variant="primary"
        class="text-left"
        :disabled="dataItem.submitStatus"
        @click="submitData(false)"
      >
        Kaydet
      </b-button>
      <b-button
        variant="primary"
        class="text-left ml-2"
        :disabled="dataItem.submitStatus"
        @click="submitData(true)"
      >
        Kaydet ve Stok Girişi Yap
      </b-button>
    </div>
  </div>
</template>
<script>
import { BCard, BButton } from 'bootstrap-vue'
import StockForm from '@/views/Admin/Stocks/Components/stockForm.vue'
import { ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'AddStocks',
  components: {
    StockForm,
    BCard,
    BButton,
    ValidationObserver,
  },
  data() {
    return {
      redirectLines: false,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['stocks/dataItem']
    },
    saveData() {
      return this.$store.getters['stocks/dataSaveStatus']
    },
  },
  watch: {
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
        this.dataItem.submitStatus = false
        if (this.redirectLines) {
          this.$router.push(`/app/stocks/addlines/${val.id}`)
        } else {
          this.$router.push(`/app/stocks/view/${val.id}`)
        }
        this.dataItem.submitStatus = false
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
    },
  },
  created() {
    this.$store.commit('stocks/RESET_DATA_ITEM')
  },
  methods: {
    submitData(toLines = false) {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.dataItem.submitStatus = true
          this.redirectLines = toLines === true
          this.$store.dispatch('stocks/saveData', this.dataItem)
        }
      })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
