<template>
  <b-form-group
    label="Birim"
    label-for="id_units"
  >
    <validation-provider
      #default="{ errors }"
      name="Birim"
      rules="required"
    >
      <v-select
        id="id_units"
        v-model="dataItem.id_units"
        :options="units"
        label="title"
        :reduce="item => item.id"
        placeholder="Seçiniz"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>
<script>
import { BFormGroup } from 'bootstrap-vue'
import { localize, ValidationProvider } from 'vee-validate'
import { required } from '@core/utils/validations/validations'
import vSelect from 'vue-select'

export default {
  name: 'UnitsCard',
  components: { ValidationProvider, vSelect, BFormGroup },
  data() {
    return {
      required,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['stocks/dataItem']
    },
    units() {
      return this.$store.getters['units/dataList']
    },
  },
  created() {
    localize('tr')
    this.getUnits()
  },
  methods: {
    getUnits() {
      this.$store.dispatch('units/getDataList')
    },
  },
}
</script>
